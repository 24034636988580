require('./slick.extention');
import './lazyload';

(function ($) {


    $(document).ready(function () {
        window.scrollTo(0, 0);
    });

})(jQuery);
