(function ($) {
    
    $.fn.isInViewport = function () {
        let elementTop = $(this).offset().top;
        let elementBottom = elementTop + $(this).outerHeight();
        let viewportTop = $(window).scrollTop();
        let viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };
    
    let $slider = $('.slick-initialized');
    
    if ($slider.length > 0) {
        const videoElements = $slider.find('.slick-slide video');
    
        $(window).on('load', function () {
            $slider.slick('refresh');
            $('.bigButton').click(function () {
                const $this = $(this);
                const videoId = $this.parent().find('video').attr('id');
                if (!videoId) {
                    return;
                }
            
                const video = document.getElementById(videoId);
                if (video.paused) {
                    video.play();
                    $this.addClass('buttonHidden').removeClass('buttonShow');
                    // disable autoplay
                    video.addEventListener('playing', function () {
                        video.loop = false;
                        $slider.slick('slickPause');
                    });
                
                    // als video end dan next slide
                    video.addEventListener('ended', function () {
                        $slider.slick('slickNext');
                        this.currentTime = 0;
                        $this.removeClass('buttonHidden').addClass('buttonShow');
                        $slider.slick('slickPlay');
                    });
                }
            
                else if (video.played) {
                    video.pause();
                    $this.removeClass('buttonHidden').addClass('buttonShow');
                    $slider.slick('slickPlay');
                }
            });
        });
    
        $(window).on('scroll', function () {
            if ($(window).width() > 580 && $slider.isInViewport()) {
                $slider.slick('slickPlay');
            
                videoElements.each(function () {
                
                    this.play();
                
                    // disable autoplay
                    this.addEventListener('playing', function () {
                        $slider.slick('slickPause');
                    });
                
                    this.addEventListener('ended', function () {
                        $slider.slick('slickNext');
                        this.currentTime = 0;
                    });
                });
            
                // als slide key is active video.play()
                $slider.on('afterChange', function (slick, current) {
                
                    const {currentSlide} = current;
                
                    const $slide = $(current.$slides[currentSlide]);
                    const slideVideoElements = $slide.find('video');
                
                    if (slideVideoElements.length > 0) {
                        videoElements.each(function () {
                        
                            this.pause();
                        });
                    
                        slideVideoElements.each(function () {
                        
                            const videoId = $(this).attr('id');
                            const video = document.getElementById(videoId);
                        
                            this.play();
                        
                            // disable autoplay
                            video.addEventListener('playing', function () {
                                video.loop = false;
                                $slider.slick('slickPause');
                            });
                        
                            // als video end dan next slide
                            video.addEventListener('ended', function () {
                                $slider.slick('slickNext');
                                $slider.slick('slickPlay');
                            });
                        });
                    }
                });
            }
            if (!$slider.isInViewport()) {
                $slider.slick('slickPause');
                videoElements.each(function () {
                
                    this.pause();
                
                });
            }
        });
    }
    
})(jQuery);