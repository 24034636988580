require('intersection-observer');
import lozad from 'lozad';

IntersectionObserver.prototype.POLL_INTERVAL = 100;

const ob = lozad('.lozad', {
    threshold: 0.1,
    loaded: function(el) {
        el.classList.add('loaded');
    },
});

const ob2 = lozad('.lazy', {
    threshold: 0.1,
    loaded: function(el) {
        el.classList.add('loaded');
    },
});

ob.observe();
ob2.observe();
